import request from "@/utils/request";
import syslogApi from '@/const/apiUrl/syslog';
import method from "@/const/request/requestMethod";



//登录日志
export function syslogLoginList(Data) {
    return request({
        url: syslogApi.syslogLoginList,
        method: method.GET,
        data: Data
    })
}

//操作日志
export function syslogList(Data) {
    return request({
        url: syslogApi.syslogList,
        method: method.GET,
        data: Data
    })
}

//根据id获取日志
export function syslogGetDetail(Data) {
    return request({
        url: syslogApi.syslogGetDetail,
        method: method.GET,
        data: Data,
        
    })
}