<template>
  <div style="margin-top: 10px;">
    <el-card class="box-card">
      <div style="margin-left: 30px">
        <div class="box"></div>
        <h2 class="ntext">基本信息</h2>
      </div>
      <div class="text item">
        <div class="dcenter">
          <div class="main">
            <div class="shorttext">访问地址</div>
            <div class="shorttext">所属模块</div>
            <div class="shorttext">业务功能</div>
            <div class="shorttext">执行方法</div>
            <div class="shorttext">主机IP</div>
            <br/>
            <div class="shorttext">操作人</div>
            <div class="shorttext">联系电话</div>
            <div class="shorttext">角色名称</div>
            <div class="shorttext">操作时间</div>
          </div>
        </div>
        <div class="dcenter">
          <div class="main">
            <div class="longtext">{{ info.requestUri }}</div>
            <div class="longtext">{{ info.module }}</div>
            <div class="longtext">{{ info.function }}</div>
            <div class="longtext">{{ info.method }}</div>
            <div class="longtext">{{ info.hostIp }}</div>
            <br/>
            <div class="longtext">{{ info.operator }}</div>
            <div class="longtext">{{ info.mobile }}</div>
            <div class="longtext">{{ info.roleName }}</div>
            <div class="longtext">{{ info.logTime }}</div>
          </div>
        </div>
      </div>
      <div style="margin-left: 30px">
        <div class="box"></div>
        <h2 class="ntext">日志内容</h2>
      </div>
      <div class="text item">
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入内容"
          :value="textarea"
        >
        </el-input>
      </div>
      <div style="margin-left: 30px">
        <div class="box"></div>
        <h2 class="ntext">操作前</h2>
      </div>
      <div class="text item">
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入内容"
          :value="value"
        >
        </el-input>
      </div>
      <div style="margin-left: 30px">
        <div class="box"></div>
        <h2 class="ntext">操作后</h2>
      </div>
      <div class="text item">
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入内容"
          :value="text"
        >
        </el-input>
      </div>
      <div class="footdokter">
        <el-button class="btn" @click="$router.push('/syslog/list')"
          >返回</el-button
        >
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  syslogGetDetail
} from "@/api/syslog";
export default {
  data() {
    return {
      textarea: "",
      value:'',
      text:"",
      info:{}, //详情
    };
  },
  created() {
    syslogGetDetail({id:this.$route.query.id}).then(res=>{
        this.info=res.data
        this.textarea=res.data.content
        this.value=res.data.before
        this.text=res.data.after
    })
  },

  methods: {

  },
};
</script>

<style lang='less' scoped>
.type {
  font-weight: 700;
  margin: 20px 0;
  font-size: 20px;
  margin-left: 25px;
}
.text {
  font-size: 14px;
}

.item {
  padding: 28px 30px;
}

.box-card {
  width: 100%;
}
.box {
  width: 15px;
  height: 15px;
  background-color: #279dfb;
  display: inline-block;
}
.ntext {
  display: inline-block;
  margin-left: 5px;
  font-weight: 700;
  font-size: 18px;
}
.dcenter {
  width: 100%;
  border: 1px solid #efefef;
  .main {
    display: flex;
    .shorttext {
      border-right: 1px solid #efefef;
      width: 360px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background-color: #f9fafc;
      font-size: 14px;
      font-weight: 700;
    }
    .longtext {
      border-right: 1px solid #efefef;
      width: 360px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      font-size: 14px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.mainfooter {
  border-bottom: 1px solid #efefef;
}
.domain {
  .order {
    color: #0066ff;
  }
  //   border: 1px solid #efefef;
  border-right: 1px solid #efefef;
  border-left: 1px solid #efefef;
  border-top: 1px solid #efefef;
  width: 100%;
  .autoheader {
    display: inline-block;
    height: 46px;
    width: 160px;
    border-right: 1px solid #efefef;
    background: #f9fafc;
    text-align: center;
    line-height: 46px;
    font-weight: 600;
    color: #333333;
    font-size: 14px;
  }
  .autoheaders {
    display: inline-block;
    height: 80px;
    width: 160px;
    border-right: 1px solid #efefef;
    background: #f9fafc;
    text-align: center;
    line-height: 80px;
    font-weight: 600;
    color: #333333;
    font-size: 14px;
  }

  img {
    height: 80px;
    width: 80px;
  }

  p {
    display: inline-block;
    margin-left: 30px;
  }
  input {
    display: inline-block;
    width: 60px;
    height: 24px;
    outline: none;
    border: 1px solid #efefef;
    padding: 5px;
    font-weight: normal;
  }
  .mainfooters {
    height: 80px;
    display: flex;
  }
}
.footdokter {
  display: flex;
  justify-content: center;
  .btn {
    width: 100px;
    background-color: #279dfb;
    border-radius: 10px;
    color: #fff;
  }
}
.tekst {
  font-size: 16px;
  color: #000;
  margin-top: 15px;
  margin-left: 30px;
}
</style>
