const syslogApi = {
    //登录日志
    syslogLoginList: '/syslog/loginList',
    //根据ID启用[停用]店铺
    syslogList: '/syslog/list',
    //根据ID获取日志
    syslogGetDetail : '/syslog/getById',
   
}


export default syslogApi